import { isArray, isEmpty } from 'lodash';
import { DateTime, Duration } from 'luxon';

import { DATETIME_FORMAT } from '../constants';

const increments = ['minutes', 'hours', 'days'];

/**
 * Formats a timestamp into a human-readable relative time string
 * @param {string} timestamp - ISO timestamp string
 * @param {string} timezone - IANA timezone string (e.g., 'America/New_York')
 * @param {string} locale - Locale string (e.g., 'en-US')
 * @returns {string} Human readable relative time (e.g., "45 minutes ago", "2 hours ago")
 */
const getRelativeFormat = (timestamp, timezone = 'UTC', locale = 'en-US') => {
  const now = DateTime.now().setZone(timezone);
  const time = DateTime.fromISO(timestamp).setZone(timezone).setLocale(locale);

  // Calculate the difference
  const diff = now.diff(time, ['days', 'hours', 'minutes', 'seconds']);

  // Convert to object for easier handling
  const { days, hours, minutes, seconds } = diff.toObject();

  // Handle future dates
  if (seconds < 0) {
    return time.toRelativeCalendar();
  }

  // Handle different time ranges
  if (days >= 7) {
    return time.toFormat('MMM d, yyyy');
  }

  if (days > 0) {
    return `${Math.floor(days)} day${days > 1 ? 's' : ''} ago`;
  }

  if (hours > 0) {
    return `${Math.floor(hours)} hour${hours > 1 ? 's' : ''} ago`;
  }

  if (minutes > 0) {
    return `${Math.floor(minutes)} minute${minutes > 1 ? 's' : ''} ago`;
  }

  return 'just now';
};

const getDurationFormat = (
  start,
  end,
  timezone,
  locale,
  isShort,
  round = false,
) => {
  // ignore seconds to remove decimals
  const getISODate = (timestamp, timezone) =>
    DateTime.fromISO(timestamp).startOf('minute').setZone(timezone);

  const endDateTime = getISODate(end, timezone);
  const startDateTime = getISODate(start, timezone);

  const diff = endDateTime.diff(startDateTime, increments);
  const existingUnits = increments.filter(
    (unit) => Math.round(Math.abs(diff[unit])) !== 0,
  );

  if (isShort) {
    const durationTime = endDateTime
      .setLocale(locale)
      .diff(startDateTime, existingUnits);

    let duration = [];
    const days = durationTime.get('days') % 365;
    const years = Math.floor(durationTime.get('days') / 365);
    const hours = durationTime.get('hours');
    const minutes = durationTime.get('minutes');
    if (years > 0) {
      duration.push(`${years}y`);
    }
    if (days > 0) {
      duration.push(`${days}d`);
    }
    if (hours > 0 && (!round || !days)) {
      duration.push(`${hours}h`);
    }
    if (minutes > 0 && (!round || (!hours && !days))) {
      duration.push(`${minutes}m`);
    }
    return duration.join(' ');
  }

  return endDateTime
    .setLocale(locale)
    .diff(startDateTime, existingUnits)
    .toHuman();
};

export const isoDurationToHumanReadable = (isoDuration) => {
  const duration = Duration.fromISO(isoDuration);

  if (!duration.isValid) {
    // Return a default message or handle this case as you see fit.
    return 'Invalid duration';
  }

  if (duration.as('hours') >= 1) {
    return duration.toFormat("h 'hours' m 'minutes'");
  }
  return duration.toFormat("m 'minutes'");
};

export const secondsToHumanReadable = (seconds) => {
  if (!seconds || isNaN(seconds)) {
    return 'Invalid duration';
  }

  const days = Math.floor(seconds / 86400); 
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const parts = [];
  
  if (days > 0) {
    parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);
  }
  
  if (hours > 0) {
    parts.push(`${hours} ${hours === 1 ? 'hour' : 'hours'}`);
  }
  
  if (minutes > 0) {
    parts.push(`${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`);
  }
  
  if (parts.length === 0) {
    return '0 minutes';
  }
  
  return parts.join(' ');
};

/**
 * Uses Luxon's DateTime to convert ISO datetime to formatted string
 *
 * @param {string} timestamp - raw ISO string
 * @param {string} [format=friendly] - format type
 * @param {Object} [opts] - options to affect the format
 * @param {string} [opts.timezone=UTC] - timezone
 * @param {(string|Object)} [opts.customFormat] - custom string format or Luxon preset object
 * @param {string} [opts.endTime] - used to calculate duration from timestamp
 * @param {string} [opts.locale] - locale (currently not supported)
 *
 * @returns formatted datetime string
 */
export const getFormattedDateTime = (
  timestamp,
  format = 'friendly',
  opts = {},
) => {
  const {
    timezone = 'UTC',
    customFormat = '',
    endTime = '',
    locale = 'en-US',
    round = false,
  } = opts;
  const dateTime = isArray(timestamp)
    ? timestamp.map((ts) => DateTime.fromISO(ts))
    : DateTime.fromISO(timestamp);
  let targetFormat = null;
  let formattedDateTime = null;
  let relativeFormat = null;

  const isToday = (someDate) => {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  switch (format) {
    // Outputs in UTC format, e.g., "2023-11-17 13:36 UTC"
    case DATETIME_FORMAT.utc:
      formattedDateTime = dateTime.toUTC().toFormat('yyyy-MM-dd HH:mm z');
      break;
    // Outputs using a custom format; requires `customFormat` option
    case DATETIME_FORMAT.custom:
      if (customFormat.length === 0 || isEmpty(customFormat)) {
        return 'Custom format is empty!';
      }
      if (typeof customFormat === 'object') {
        formattedDateTime = dateTime
          .setZone(timezone)
          .toLocaleString(customFormat, { locale: locale });
      } else {
        targetFormat = customFormat;
      }
      break;
    // Outputs only the date, e.g., "November 17, 2023"
    case DATETIME_FORMAT.date:
      targetFormat = 'DD';
      break;
    // Outputs numeric date, e.g., "11/17/2023"
    case DATETIME_FORMAT.date_numeric:
      formattedDateTime = dateTime
        .setZone(timezone)
        .toLocaleString(DateTime.DATE_SHORT, { locale: locale });
      break;
    case DATETIME_FORMAT.date_range:
      formattedDateTime = dateTime
        .map((dt) => {
          return dt
            .setZone(timezone)
            .toLocaleString(DateTime.DATE_SHORT, { locale: locale });
        })
        .join(' - ');
      break;
    // Outputs a conditional format, e.g., "Today, 1:36 PM UTC" for today otherwise
    // "11/17/2023, 1:36 PM UTC" for a different day
    case DATETIME_FORMAT.conditional:
      if (isToday(timestamp)) {
        targetFormat = "'Today', t ZZZZ";
      } else {
        targetFormat = 'MM/dd/yyyy, t ZZZZ';
      }
      break;
    // Outputs a short duration, e.g., "1m" or "less than a minute"
    case DATETIME_FORMAT.duration_short:
      if (endTime.length === 0) {
        return 'Endtime is empty!';
      }
      if (DateTime.fromISO(endTime).hasSame(dateTime, 'minute')) {
        if (round) {
          return 'less than a minute';
        } else {
          return '0m';
        }
      }
      formattedDateTime = getDurationFormat(
        timestamp,
        endTime,
        timezone,
        locale,
        true,
        round,
      );
      break;
    // Outputs a long duration, e.g., "1 minute" or "less than a minute"
    case DATETIME_FORMAT.duration:
      if (endTime.length === 0) {
        return 'Endtime is empty!';
      }
      if (DateTime.fromISO(endTime).hasSame(dateTime, 'minute')) {
        return 'less than a minute';
      }
      formattedDateTime = getDurationFormat(
        timestamp,
        endTime,
        timezone,
        locale,
      );
      break;
    // Outputs a legacy format, e.g., "November 17, 2023 1:36 PM UTC"
    case DATETIME_FORMAT.legacy:
      targetFormat = 'f ZZZZ';
      break;
    case DATETIME_FORMAT.long:
      targetFormat = 'MM/dd/yyyy, hh:mm:ss a';
      break;
    // Outputs the raw timestamp
    case DATETIME_FORMAT.raw:
      formattedDateTime = timestamp;
      break;
    // Outputs a relative time, e.g., "2 minutes ago"
    case DATETIME_FORMAT.relative:
      formattedDateTime = getRelativeFormat(timestamp, timezone, locale);
      break;
    // Outputs only the time and time zone, e.g., "1:36 PM UTC"
    case DATETIME_FORMAT.time:
      targetFormat = 't ZZZZ';
      break;
    // Outputs the day of the week with friendly format if it's longer than 1 day ago, e.g., "Monday, November 17, 2023 1:36 PM UTC"
    // Otherwise outputs 'ff ZZZZ'
    case DATETIME_FORMAT.withDay:
      relativeFormat = getRelativeFormat(timestamp, timezone, locale);
      if (relativeFormat.includes('today')) {
        targetFormat = 'ff ZZZZ';
      } else {
        targetFormat = 'EEEE, ff ZZZZ';
      }
      break;
    // Outputs a tight format, e.g., "Mar 17 1:36 PM UTC"
    case DATETIME_FORMAT.tight:
      targetFormat = 'LLL dd t ZZZZ';
      break;

    case DATETIME_FORMAT.short:
      targetFormat = 'MMM dd, yyyy HH:mm:ss';
      break;

    // Outputs a friendly format, e.g., "November 17, 2023 1:36 PM UTC"
    default:
    case DATETIME_FORMAT.friendly:
      targetFormat = 'ff ZZZZ';
      break;
  }

  return targetFormat
    ? dateTime.setZone(timezone).setLocale(locale).toFormat(targetFormat)
    : formattedDateTime;
};
