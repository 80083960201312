import React from 'react';

import { ThemeProvider } from '@firehydrant/design-system';
import {
  QueryClient as V5QueryClient,
  QueryClientProvider as V5QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools as V5Devtools } from '@tanstack/react-query-devtools';
import { AuthContextProvider } from 'contexts/AuthContext/AuthContextProvider';
import { UserContextProvider } from 'contexts/UserContext/UserContextProvider';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import ErrorBoundary from 'components/common/ErrorBoundary';
import { PosthogProvider } from 'components/common/PosthogProvider';
import { ToastContextProvider } from 'components/common/ToastBannerProvider/ToastBannerProvider';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});

export const v5QueryClient = new V5QueryClient({
  defaultOptions: {
    queries: { retry: false, refetchOnWindowFocus: false },
  },
});

export const SPAWrapper = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <V5QueryClientProvider client={v5QueryClient}>
        <AuthContextProvider>
          <UserContextProvider>
            <ErrorBoundary>
              <PosthogProvider>
                <CookiesProvider>
                  <ThemeProvider resetCSS={false} nonce={window.nonceKey}>
                    <>
                      <ReactQueryDevtools initialIsOpen={false} />
                      <V5Devtools initialIsOpen={false} />
                      <ToastContextProvider>{children}</ToastContextProvider>
                    </>
                  </ThemeProvider>
                </CookiesProvider>
              </PosthogProvider>
            </ErrorBoundary>
          </UserContextProvider>
        </AuthContextProvider>
      </V5QueryClientProvider>
    </QueryClientProvider>
  );
};

export default SPAWrapper;
