/**
 * Color Theme Configuration
 * 
 * This file defines our color system including both raw color values and semantic tokens.
 * 
 * Semantic Token Usage Guide:
 * --------------------------
 * 1. surface.* - Used for backgrounds and container elements
 *    - surface.primary: Main background colors
 *    - surface.secondary: Secondary/alternative backgrounds
 *    - surface.tertiary: Third-level backgrounds
 *    - surface.action.*: Interactive element backgrounds
 *    - surface.feedback.*: Status and feedback backgrounds
 *    - surface.color.*: Brand/theme color backgrounds
 * 
 * 2. content.* - Used for foreground elements and text
 *    - content.primary: Main text color
 *    - content.secondary: Less prominent text
 *    - content.tertiary: Subtle text, icons, and decorative elements
 *    - content.action.*: Interactive text elements
 *    - content.feedback.*: Status and feedback text
 * 
 * 3. stroke.* - Used for borders, dividers, and lines
 *    - stroke.primary: Standard borders
 *    - stroke.secondary: Subtle borders
 *    - stroke.action.*: Interactive element borders
 *    - stroke.feedback.*: Status and feedback borders
 * 
 * Color Scale Guide:
 * -----------------
 * Each color has a scale from 50-1200:
 * - 50-100: Lightest shades, best for subtle backgrounds
 * - 200-400: Light shades, good for secondary elements
 * - 500-700: Primary shades, main brand colors
 * - 800-1000: Dark shades, good for text and contrast
 * - 1100-1200: Darkest shades, used for special cases
 */

const gray = {
  0: '#ffffff',
  50: '#F3F4F7',
  100: '#EAECF0',
  200: '#DCDDE0',
  300: '#C5C8D8',
  400: '#B6B9C9',
  500: '#A4A8BC',
  600: '#6C6F7F',
  700: '#464A53',
  800: '#343842',
  900: '#2B2F36',
  1000: '#25272E',
  1100: '#202228',
  1200: '#202228',
};

const red = {
  50: '#FCEDED',
  100: '#F8D5D3',
  200: '#FACCCC',
  300: '#FF8A8A',
  400: '#EF726B',
  500: '#E4544E',
  600: '#DC3931',
  700: '#B72F29',
  800: '#932621',
  900: '#701D19',
  1000: '#451D1C',
  1100: '#300C0B',
  1200: '#300C0B',
};

const oceanBlue = {
  50: '#EAF1FE',
  100: '#DCE8FD',
  200: '#C0D6FB',
  300: '#95BAF9',
  400: '#71A2F8',
  500: '#4C8AF6',
  600: '#1767F2',
  700: '#175BD1',
  800: '#1349A7',
  900: '#0E387F',
  1000: '#142A4F',
  1100: '#061735',
};

const white = '#FFFFFF';
const black = '#1B1D22';

const hexToRGBA = (hex, alpha = 1) => {
  // Remove # if present
  hex = hex.replace('#', '');

  // Parse hex values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return rgba string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Create opacity variants for a color
const createOpacityVariants = (hex) => ({
  4: hexToRGBA(hex, 0.04),
  8: hexToRGBA(hex, 0.08),
  12: hexToRGBA(hex, 0.12),
  32: hexToRGBA(hex, 0.32),
  64: hexToRGBA(hex, 0.64),
});

// Opacity variants
const opacityColors = {
  white: createOpacityVariants('#FFFFFF'),
  black: createOpacityVariants('#000000'),
  ocean: createOpacityVariants('#1349A7'), // Ocean-800
  blue: createOpacityVariants('#6646DA'), // Blue-800
  purple: createOpacityVariants('#6646DA'), // Purple-800
  green: createOpacityVariants('#2C865C'), // Green-700
  yellow: createOpacityVariants('#BA9031'), // Yellow-700
  orange: createOpacityVariants('#BD5B1D'), // Orange-700
  red: createOpacityVariants('#B72F29'), // Red-800
  beige: createOpacityVariants('#69615A'), // Beige-800
  gray: createOpacityVariants('#6C6F7F'), // Gray-600
};

export const semanticTokens = {
  colors: {
    // Neutral tokens
    'surface.primary': {
      _light: 'white',
      _dark: 'black',
    },
    'surface.secondary': {
      _light: 'gray.50',
      _dark: 'gray.1100',
    },
    'surface.tertiary': {
      _light: 'gray.100',
      _dark: 'gray.1000',
    },
    'surface.sharp': {
      _light: 'gray.800',
      _dark: 'gray.100',
    },
    'surface.contrast': {
      _light: 'gray.1000',
      _dark: 'gray.50',
    },
    'surface.modal': {
      _light: 'blackAlpha.600',
      _dark: 'blackAlpha.600',
    },

    // Surface / Action
    'surface.action.primary': {
      _light: 'oceanBlue.600',
      _dark: 'oceanBlue.600',
    },
    'surface.action.secondary': {
      _light: 'white',
      _dark: 'black',
    },

    // Hover states with opacity
    'surface.hover.ocean': {
      _light: opacityColors.ocean['8'],
      _dark: opacityColors.ocean['8'],
    },
    'surface.hover.blue': {
      _light: opacityColors.blue['8'],
      _dark: opacityColors.blue['8'],
    },
    'surface.hover.purple': {
      _light: opacityColors.purple['8'],
      _dark: opacityColors.purple['8'],
    },
    'surface.hover.green': {
      _light: opacityColors.green['8'],
      _dark: opacityColors.green['8'],
    },
    'surface.hover.yellow': {
      _light: opacityColors.yellow['8'],
      _dark: opacityColors.yellow['8'],
    },
    'surface.hover.orange': {
      _light: opacityColors.orange['8'],
      _dark: opacityColors.orange['8'],
    },
    'surface.hover.red': {
      _light: opacityColors.red['8'],
      _dark: opacityColors.red['8'],
    },
    'surface.hover.beige': {
      _light: opacityColors.beige['8'],
      _dark: opacityColors.beige['8'],
    },

    // Action states with opacity
    'surface.action.hover.secondary': {
      _light: opacityColors.black['4'],
      _dark: opacityColors.black['4'],
    },

    // Surface Feedback Success tokens
    'surface.feedback.success.soft': {
      _light: 'green.50',
      _dark: 'green.1000',
    },
    'surface.feedback.success.regular': {
      _light: 'green.200',
      _dark: 'green.800',
    },
    'surface.feedback.success.sharp': {
      _light: 'green.500',
      _dark: 'green.500',
    },

    // Surface Feedback Error tokens
    'surface.feedback.error.soft': {
      _light: 'red.50',
      _dark: 'red.1000',
    },
    'surface.feedback.error.regular': {
      _light: 'red.200',
      _dark: 'red.800',
    },
    'surface.feedback.error.sharp': {
      _light: 'red.500',
      _dark: 'red.500',
    },

    // Surface Feedback Critical tokens
    'surface.feedback.critical.soft': {
      _light: 'orange.50',
      _dark: 'orange.1000',
    },
    'surface.feedback.critical.regular': {
      _light: 'orange.200',
      _dark: 'orange.800',
    },
    'surface.feedback.critical.sharp': {
      _light: 'orange.500',
      _dark: 'orange.500',
    },

    // Surface Severity tokens
    'surface.severity.high': {
      _light: 'red.300',
      _dark: 'red.400',
    },
    'surface.severity.medium': {
      _light: 'orange.300',
      _dark: 'orange.400',
    },
    'surface.severity.low': {
      _light: 'yellow.300',
      _dark: 'yellow.400',
    },
    'surface.severity.triage': {
      _light: 'blue.300',
      _dark: 'blue.400',
    },

    // Surface Color tokens
    'surface.color.ocean.soft': {
      _light: 'oceanBlue.50',
      _dark: 'oceanBlue.1000',
    },
    'surface.color.ocean.regular': {
      _light: 'oceanBlue.200',
      _dark: 'oceanBlue.600',
    },
    'surface.color.blue.soft': {
      _light: 'blue.50',
      _dark: 'blue.1000',
    },
    'surface.color.blue.regular': {
      _light: 'blue.200',
      _dark: 'blue.600',
    },
    'surface.color.purple.soft': {
      _light: 'purple.50',
      _dark: 'purple.1000',
    },
    'surface.color.purple.regular': {
      _light: 'purple.200',
      _dark: 'purple.600',
    },
    'surface.color.yellow.soft': {
      _light: 'yellow.50',
      _dark: 'yellow.1000',
    },
    'surface.color.yellow.regular': {
      _light: 'yellow.200',
      _dark: 'yellow.600',
    },
    'surface.color.beige.soft': {
      _light: 'beige.50',
      _dark: 'beige.1000',
    },
    'surface.color.beige.regular': {
      _light: 'beige.200',
      _dark: 'beige.600',
    },
    'surface.color.green.soft': {
      _light: 'green.50',
      _dark: 'green.1000',
    },
    'surface.color.green.regular': {
      _light: 'green.200',
      _dark: 'green.600',
    },
    'surface.color.orange.soft': {
      _light: 'orange.50',
      _dark: 'orange.1000',
    },
    'surface.color.orange.regular': {
      _light: 'orange.200',
      _dark: 'orange.600',
    },
    'surface.color.red.soft': {
      _light: 'red.50',
      _dark: 'red.1000',
    },
    'surface.color.red.regular': {
      _light: 'red.200',
      _dark: 'red.600',
    },

    // Base Stroke tokens
    'stroke.primary': {
      _light: opacityColors.black['8'],
      _dark: opacityColors.white['4'],
    },
    'stroke.inverse': {
      _light: opacityColors.white['12'],
      _dark: opacityColors.gray['12'],
    },
    'stroke.negative': {
      _light: 'white',
      _dark: 'black',
    },

    // Stroke Action tokens
    'stroke.action.primary': {
      _light: opacityColors.ocean['32'],
      _dark: opacityColors.ocean['32'],
    },
    'stroke.action.secondary': {
      _light: opacityColors.black['12'],
      _dark: opacityColors.white['8'],
    },
    'stroke.action.hover': {
      _light: opacityColors.black['12'],
      _dark: opacityColors.white['8'],
    },
    'stroke.action.unselected': {
      _light: opacityColors.black['12'],
      _dark: opacityColors.white['8'],
    },
    'stroke.action.disabled': {
      _light: opacityColors.black['12'],
      _dark: opacityColors.white['4'],
    },

    // Stroke Colored tokens
    'stroke.ocean': {
      _light: opacityColors.ocean['32'],
      _dark: opacityColors.ocean['32'],
    },
    'stroke.blue': {
      _light: opacityColors.blue['32'],
      _dark: opacityColors.blue['32'],
    },
    'stroke.purple': {
      _light: opacityColors.purple['32'],
      _dark: opacityColors.purple['32'],
    },
    'stroke.green': {
      _light: opacityColors.green['32'],
      _dark: opacityColors.green['32'],
    },
    'stroke.yellow': {
      _light: opacityColors.yellow['32'],
      _dark: opacityColors.yellow['32'],
    },
    'stroke.orange': {
      _light: opacityColors.orange['32'],
      _dark: opacityColors.orange['32'],
    },
    'stroke.red': {
      _light: opacityColors.red['32'],
      _dark: opacityColors.red['32'],
    },
    'stroke.beige': {
      _light: opacityColors.beige['32'],
      _dark: opacityColors.beige['32'],
    },

    // Base Content tokens
    'content.primary': {
      _light: 'gray.900',
      _dark: 'gray.50',
    },
    'content.secondary': {
      _light: 'gray.700',
      _dark: 'gray.200',
    },
    'content.tertiary': {
      _light: 'gray.600',
      _dark: 'gray.500',
    },
    'content.inverse': {
      _light: 'white',
      _dark: 'black',
    },

    // Content Action tokens
    'content.action.primary': {
      _light: 'white',
      _dark: 'white',
    },
    'content.action.secondary': {
      _light: 'gray.800',
      _dark: 'gray.100',
    },
    'content.text.button': {
      _light: 'oceanBlue.600',
      _dark: 'oceanBlue.500',
    },
    'content.action.unselected': {
      _light: 'gray.700',
      _dark: 'gray.600',
    },
    'content.action.disabled': {
      _light: 'gray.500',
      _dark: 'gray.500',
    },

    // Content Feedback Success tokens
    'content.feedback.success.primary': {
      _light: 'green.800',
      _dark: 'green.200',
    },
    'content.feedback.success.secondary': {
      _light: 'green.700',
      _dark: 'green.400',
    },

    // Content Feedback Error tokens
    'content.feedback.error.primary': {
      _light: 'red.800',
      _dark: 'red.200',
    },
    'content.feedback.error.secondary': {
      _light: 'red.700',
      _dark: 'red.400',
    },

    // Content Feedback Critical tokens
    'content.feedback.critical.primary': {
      _light: 'orange.800',
      _dark: 'orange.200',
    },
    'content.feedback.critical.secondary': {
      _light: 'orange.700',
      _dark: 'orange.400',
    },

    // Content Colored tokens
    'content.ocean.primary': {
      _light: 'oceanBlue.800',
      _dark: 'oceanBlue.200',
    },
    'content.ocean.secondary': {
      _light: 'oceanBlue.700',
      _dark: 'oceanBlue.300',
    },
    'content.blue.primary': {
      _light: 'blue.800',
      _dark: 'blue.200',
    },
    'content.blue.secondary': {
      _light: 'blue.700',
      _dark: 'blue.300',
    },
    'content.purple.primary': {
      _light: 'purple.800',
      _dark: 'purple.200',
    },
    'content.purple.secondary': {
      _light: 'purple.700',
      _dark: 'purple.300',
    },
    'content.yellow.primary': {
      _light: 'yellow.800',
      _dark: 'yellow.200',
    },
    'content.yellow.secondary': {
      _light: 'yellow.700',
      _dark: 'yellow.300',
    },
    'content.beige.primary': {
      _light: 'beige.800',
      _dark: 'beige.200',
    },
    'content.beige.secondary': {
      _light: 'beige.700',
      _dark: 'beige.300',
    },
    'content.green.primary': {
      _light: 'green.800',
      _dark: 'green.200',
    },
    'content.green.secondary': {
      _light: 'green.700',
      _dark: 'green.300',
    },
    'content.orange.primary': {
      _light: 'orange.800',
      _dark: 'orange.200',
    },
    'content.orange.secondary': {
      _light: 'orange.700',
      _dark: 'orange.300',
    },
    'content.red.primary': {
      _light: 'red.800',
      _dark: 'red.200',
    },
    'content.red.secondary': {
      _light: 'red.700',
      _dark: 'red.300',
    },

    shadow: {
      _light: 'rgba(0, 0, 0, 0.08)',
      _dark: 'rgba(0, 0, 0, 0.48)',
    },

    error: 'red.700',
    foreground: 'surface.secondary',
    background: 'surface.secondary',
  },
};

const colorButtonPrimaryDefault = oceanBlue['600'];
const colorButtonPrimaryHover = oceanBlue['700'];
const colorButtonPrimaryActive = oceanBlue['800'];
const colorButtonPrimaryGhostHover = oceanBlue['50'];
const colorButtonPrimaryGhostActive = oceanBlue['100'];

export const colors = {
  grey: gray,
  gray,
  red,
  oceanBlue,
  white,
  black,

  'button-primary': {
    50: colorButtonPrimaryGhostHover,
    100: colorButtonPrimaryGhostActive,
    200: colorButtonPrimaryHover,
    300: oceanBlue['300'],
    400: colorButtonPrimaryHover,
    500: colorButtonPrimaryDefault,
    600: colorButtonPrimaryHover,
    700: colorButtonPrimaryActive,
  },

  'button-danger': red,

  'color-tabs-primary': oceanBlue,

  primary: {
    50: '#EAF1FE',
    100: '#DCE8FD',
    200: '#C0D6FB',
    300: '#95BAF9',
    400: '#71A2F8',
    500: '#4C8AF6',
    600: '#1767F2',
    700: '#175BD1',
    800: '#1349A7',
    900: '#0E387F',
    1000: '#142A4F',
    1100: '#061735',
  },

  blue: {
    50: '#E2F3FE',
    100: '#CFEBFD',
    200: '#9CD7FC',
    300: '#68C2FA',
    400: '#2EAAF8',
    500: '#1192E3',
    600: '#0E7CC1',
    700: '#0C67A0',
    800: '#1D4D6C',
    900: '#073F61',
    1000: '#0C2A3D',
    1100: '#031A29',
  },

  purple: {
    50: '#F2EFFC',
    100: '#E9E4FA',
    200: '#D3CAF6',
    300: '#BEB0F1',
    400: '#A996ED',
    500: '#937BE8',
    600: '#7D61E3',
    700: '#6646DA',
    800: '#5238AE',
    900: '#3E2B84',
    1000: '#2A2343',
    1100: '#1A1237',
  },

  green: {
    50: '#E8F3EE',
    100: '#CFE7DC',
    200: '#BBDDCE',
    300: '#8CC5AA',
    400: '#66B18E',
    500: '#3E9D71',
    600: '#2C865C',
    700: '#246F4C',
    800: '#1D593D',
    900: '#1D593D',
    1000: '#16432E',
    1100: '#152920',
  },

  yellow: {
    50: '#FEF5E2',
    100: '#FBE7B6',
    200: '#FBE2A9',
    300: '#FAD88C',
    400: '#F8CF70',
    500: '#ECAB18',
    600: '#BA9031',
    700: '#866A2D',
    800: '#765B1E',
    900: '#443717',
    1000: '#231F14',
    1100: '#1D1809',
  },

  orange: {
    50: '#FEEEE3',
    100: '#FCDAC4',
    200: '#FFD3B8',
    300: '#FFA96B',
    400: '#F88238',
    500: '#E67228',
    600: '#BD5B1D',
    700: '#9D4C18',
    800: '#7D3C13',
    900: '#5F2E0F',
    1000: '#3B2212',
    1100: '#271306',
  },

  teal: {
    // making teal the same as blue for now
    50: '#E2F3FE',
    100: '#CFEBFD',
    200: '#9CD7FC',
    300: '#68C2FA',
    400: '#2EAAF8',
    500: '#1192E3',
    600: '#0E7CC1',
    700: '#0C67A0',
    800: '#1D4D6C',
    900: '#073F61',
    1000: '#0C2A3D',
    1100: '#031A29',
  },

  beige: {
    50: '#F1F0F0',
    100: '#E8E7E6',
    200: '#D2CFCC',
    300: '#BCB8B4',
    400: '#A7A19C',
    500: '#928B85',
    600: '#7E766E',
    700: '#69615A',
    800: '#544D47',
    900: '#3F3B36',
    1000: '#2C2926',
    1100: '#1A1816',
  },

  magenta: {
    50: '#fcdbe3',
    100: '#fbc1cf',
    200: '#f898af',
    300: '#eb528d',
    400: '#de247e',
    500: '#c5006c',
    600: '#b90068',
    700: '#880056',
    800: '#640040',
    900: '#49002f',
  },

  // All deprecated colors below

  // Side navigation
  grey_deprecated: {
    90: '#182042',
    70: '#536685',
    50: '#8a9bb7',
    30: '#c6d0e2',
    10: '#eef1f6',
    0: '#ffffff',
  },

  // GraidentButton/Registration
  orange_deprecated: {
    90: '#933600',
    70: '#d14900',
    50: '#ff5300',
    30: '#ff9862',
    10: '#ffece3',
  },

  purple_deprecated: {
    90: '#220e6d',
    70: '#3b2492',
    50: '#614ab6',
    30: '#b2a6e3',
    10: '#f1edff',
  },

  magenta_deprecated: {
    90: '#791b96',
    70: '#a3008b',
    50: '#c5007b',
    30: '#e00066',
  },

  // used in Link component
  gradient:
    'linear(to-r, purple_deprecated.70, magenta_deprecated.90 19.27%, magenta_deprecated.70 36.46%, magenta_deprecated.50 56.77%, magenta_deprecated.30 75.52%, orange_deprecated.50)',
};

export const colorKeys = (color) =>
  Object.keys(colors[color]).reduce(
    (values, currentValue) => ({
      ...values,
      [`${color}.${currentValue}`]: colors[color][currentValue],
    }),
    {},
  );

export const colorOptions = Object.keys(colors).reduce((acc, color) => {
  return [
    ...acc,
    ...Object.keys(colors[color]).reduce((values, num) => {
      return [...values, `${color}.${num}`];
    }, []),
    ...Object.keys(semanticTokens.colors).reduce((values, key) => {
      return [...values, key];
    }, []),
  ];
}, []);
